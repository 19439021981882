@import url('https://fonts.googleapis.com/css2?family=Bodoni+Moda+SC:ital,opsz,wght@0,6..96,400..900;1,6..96,400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap');

/* Global font styling using Bodoni Moda SC */
.bodoni-moda-sc-regular {
  font-family: "Bodoni Moda SC", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.explore-world-heading {
  text-align: center;
  margin: 10px 0;
}

.explore-world-heading .small {
  font-size: 1.2rem;
  white-space: nowrap; /* Ensure text stays on one line */
}

.explore-world-heading .small-text {
  font-family: 'DM Serif Display', serif; /* Applying DM Serif Display font */
  font-size: 2rem; /* Smaller text for 'explore the world of' */
  font-weight: 400; /* Regular weight */
  color: #1e2a36; /* Dark navy for consistency */
  display: block;
}

.explore-world-heading .large-text {
  font-family: 'DM Serif Display', serif; /* Same font for large text */
  font-size: 3.5rem; /* Larger text for 'Linkosi Clothing' */
  font-weight: 400; /* Regular weight for elegance */
  color: #1e2a36; /* Same dark navy */
  display: block;
}

@media (max-width: 768px) {
  .explore-world-heading .small-text {
    font-size: 2rem; /* Adjust for tablet */
  }
  .explore-world-heading .small {
    font-size: 1.1rem;
  }
  .explore-world-heading .large-text {
    font-size: 3rem; /* Adjust for tablet */
  }
}

@media (max-width: 480px) {
  .explore-world-heading .small-text {
    font-size: 1.5rem; /* Adjust for mobile */
  }
  .explore-world-heading .small {
    font-size: 1rem;
  }
  .explore-world-heading .large-text {
    font-size: 2.5rem; /* Adjust for mobile */
  }
}

/* Prevent horizontal scrolling */
body {
  overflow-x: hidden;
}

/* Full viewport height and width */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

/* Prevent scrolling beyond sections */
section {
  min-height: 100vh;
  overflow: hidden;
}

/* Slider Section Styling */
.new-in-section {
  text-align: center;
  padding: 40px;
  opacity: 0;
  transform: translateY(50px);
  transition: all 0.8s ease-in-out;
}

.new-in-section.reveal {
  opacity: 1;
  transform: translateY(0);
}

/* Heading Styling */
.new-in-heading {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #000;
  font-family: "Bodoni Moda SC", serif; /* Applied Bodoni Moda SC font */
}

/* Slider container */
.slider {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;
}

.slider-item {
  flex: 0 0 33%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.slider-item img {
  width: 100%;
  height: auto;
  display: block;
  transition: opacity 0.3s ease;
}

/* Slider Overlay and Button */
.slider-overlay {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
}

.slider-item:hover .slider-overlay,
.slider-overlay.visible {
  opacity: 1;
}

/* Updated Button Styling */
.shop-now-btn {
  padding: 10px 20px;
  background: rgba(255, 255, 255, 0.7); /* Slightly transparent white background */
  border: none; /* No border */
  color: #000; /* Black text color */
  font-size: 1rem;
  font-family: "Bodoni Moda SC", serif; /* Applied Bodoni Moda SC font */
  cursor: pointer;
  
  transition: background 0.3s ease, color 0.3s ease;
  opacity: 0.9; /* Slight transparency */
}

.shop-now-btn:hover {
  background: rgba(255, 255, 255, 1); /* Fully opaque on hover */
  color: #000; /* Text remains black */
}

/* Pagination + Arrows Container */
.pagination-arrows-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
  position: relative;
}

/* Arrows Styling */
.slider-arrow {
  background: none;
  border: none;
  color: #000;
  cursor: pointer;
  z-index: 1;
  font-size: 30px;
}

.slider-arrow-left,
.slider-arrow-right {
  padding: 0 10px;
}

/* Pagination Dots */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.dot {
  height: 12px;
  width: 12px;
  background-color: #ddd;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.active {
  background-color: #000;
}

/* Responsive Design */
@media (max-width: 768px) {
  .slider-item {
    flex: 0 0 50%;
  }

  .new-in-heading {
    font-size: 1rem;
  }

  .pagination-arrows-container {
    gap: 10px;
  }

  .slider-arrow {
    font-size: 25px;
  }
}

/* Large Devices (Above 768px) */
@media (min-width: 769px) {
  .slider-item {
    margin: 20px;
  }
}

/* Mobile Devices (Max 480px) */
@media (max-width: 480px) {
  .new-in-section {
    padding: 0;
    margin: 0;
  }

  .slider {
    width: 100vw;
  }

  .slider-item {
    flex: 0 0 100%;
    margin: 0;
  }

  .slider-item img {
    border-radius: 0;
    width: 100vw;
    height: auto;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;500;700&display=swap');

.quicksand-regular {
  font-family: 'Quicksand', sans-serif;
  font-weight: 400; /* Regular weight */
  font-style: normal;
}

.quicksand-semibold {
  font-family: 'Quicksand', sans-serif;
  font-weight: 600; /* Semi-bold weight */
  font-style: normal;
}
