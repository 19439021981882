@import url('https://fonts.googleapis.com/css2?family=Bodoni+Moda+SC:ital,opsz,wght@0,6..96,400..900;1,6..96,400..900&display=swap');

/* Main styling for the shop section */
.new-shop-section {
  padding: 20px;
  text-align: center;
  background-color: #fff;
  font-family: 'Bodoni Moda SC', serif;
}

.new-shop-section h1 {
  font-family: 'Bodoni Moda SC', serif;
  font-weight: 400;
  font-size: 2.5rem;
  margin-bottom: 40px;
}

/* Product Grid for larger screens */
.new-product-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

/* Product Item Styling */
.new-product-item {
  text-align: center;
  position: relative; /* Allow positioning of the button */
  overflow: hidden; /* Ensure content doesn't overflow */
  opacity: 0;
  transform: translateX(100px);
  transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;
  padding: 10px;
}

.new-product-item[data-direction='left'] {
  transform: translateX(-100px);
}

.new-product-item.reveal {
  opacity: 1;
  transform: translateX(0);
}

.new-product-item img {
  width: 100%;
  height: auto;
  border: none;
}

/* Button for Product Name */
.product-name-button {
  position: absolute;
  bottom: 20px; /* Position at the bottom of the image */
  left: 50%;
  transform: translateX(-50%); /* Center the button */
  background-color: #fae7e71a; /* Transparent white background */
  color: #fff6f6; /* Black text */
  padding: 10px 20px; /* Padding for button */
  border: 2px solid rgba(131, 129, 129, 0.9); /* Solid black border */
  border-radius: 0; /* No rounded corners */
  font-size: 1rem; /* Font size */
  transition: background-color 0.3s, color 0.3s; /* Smooth background and color transition */
  text-transform: uppercase; /* Uppercase text */
  font-family: 'Bodoni Moda SC', serif; /* Font style */
}

.product-name-button:hover {
  background-color: rgba(216, 201, 201, 0.8); /* Darker background on hover */
  color: #000000; /* White text on hover */
}

/* Responsive design for smaller devices */
@media (max-width: 768px) {
  .new-product-grid {
    grid-template-columns: 1fr; /* Single column layout */
    gap: 0; /* Remove any gap between items */
  }

  .new-product-item {
    padding: 0; /* Remove padding on small devices */
  }

  .new-product-item img {
    width: 100vw; /* Full viewport width to ensure it touches both edges */
    height: auto;
    margin: 0; /* Remove any margin */
  }
}

@media (max-width: 480px) {
  /* Remove padding and margins from the section */
  .new-shop-section {
    padding: 0;
    margin: 0;
  }

  /* Ensure the product grid takes up the full viewport width */
  .new-product-grid {
    width: 100vw;
    padding: 0;
    margin: 0;
    grid-template-columns: 1fr; /* Single column for small screens */
    gap: 0; /* Remove gap between images */
  }

  /* Ensure the product items touch the edges of the screen */
  .new-product-item {
    margin: 10px; /* No margins */
    padding: 0; /* No padding */
    flex: 0 0 100%;
  }

  /* Ensure images take up the full width of the viewport */
  .new-product-item img {
    width: 100vw; /* Full viewport width */
    height: auto;
    border-radius: 0; /* No border-radius */
    margin: 0; /* Remove any default margin */
  }
}
