/* Header Styling - Default Transparent */
header {
  background-color: transparent; /* Transparent on load */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  box-shadow: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-right: 30px;
}

/* Initially hide the logo */
h2.logo {
  display: none;
  font-family: "DM Serif Display", serif;
  font-size: 24px;
  margin: 0;
  color: black; /* Will turn black after scrolling */
  transition: color 0.3s ease-in-out;
}

/* Icon styles */
.icon-container {
  display: flex;
  align-items: center;
}

.icon {
  font-size: 1.5rem;
  margin-left: 15px;
  color: white; /* White icons on initial load */
  cursor: pointer;
  transition: color 0.3s;
}

.icon:hover {
  color: #ff4081;
}

/* Green color after sign-in */
.user-icon.green {
  color: green;
}

/* Hamburger menu */
.hamburger-container {
  font-size: 1.8rem;
  cursor: pointer;
}

/* Mobile Navigation */
.mobile-nav {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 60px;
  z-index: 1000;
}

.mobile-nav.is-active {
  display: flex;
}

.mobile-nav a {
  font-family: "Bodoni Moda", serif;
  font-weight: 400;
  font-size: 16px;
  margin: 10px 0;
  text-decoration: none;
  color: #000;
}

.mobile-nav a:hover {
  color: #ff4081;
}

/* Responsive styles for small devices */
@media (max-width: 768px) {
  .icon-container {
    display: flex;
    padding: 5px;
  }

  .hamburger-container {
    display: flex;
  }

  .icon {
    font-size: 1.2rem;
    margin-left: 10px;
  }
}

/* Larger device styling - bigger icons and more padding */
@media (min-width: 768px) {
  .icon {
    font-size: 1.5rem;
    margin-left: 10px;
    padding-right: 15px;
  }
}

/* Scroll-triggered styles */
.scrolled {
  background-color: rgba(255, 255, 255, 0.9) !important; /* Force white background with transparency */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Shadow when scrolled */
}

.scrolled .icon {
  color: black !important; /* Force icons to turn black after scrolling */
}

.scrolled .logo {
  display: block; /* Show logo after scrolling */
  color: black !important; /* Turn logo text black after scrolling */
}

/* Transitions */
.icon,
.logo {
  transition: color 0.3s ease-in-out, display 0.3s ease-in-out;
}
