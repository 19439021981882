/* Main Container */
.payment-container {
  max-width: 700px;
  margin: 30px auto;
  padding: 30px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  font-family: 'Gowun Batang', serif;
}

/* Headings */
h2, h3 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

/* Billing and Payment Sections */
.billing-info, 
.payment-methods {
  margin-bottom: 30px;
}

.billing-info label, 
.payment-details label {
  display: block;
  margin-bottom: 12px;
  font-size: 1rem;
  color: black;
  font-weight: bold;
}

/* Inputs and Textareas */
.billing-info input, 
.payment-details input, 
.payment-details textarea {
  width: 100%;
  padding: 15px;
  font-size: 1rem;
  color: black;
  background-color: #fff;
  margin-top: 5px;
  margin-bottom: 20px;
  border-radius: 6px;
  border: 1px solid #aaa;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
}

.billing-info input:focus, 
.payment-details input:focus, 
.payment-details textarea:focus {
  border-color: #3cb371;
  outline: none;
}

/* Textarea */
textarea {
  resize: vertical;
}

/* Cart Items */
.cart-items .payment-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.cart-items .payment-item img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 5px;
}

.cart-items .payment-item h4 {
  font-size: 1.2rem;
  margin: 0;
}

/* Total Amount */
.cart-items h3 {
  text-align: center;
  font-size: 1.4rem;
  margin-top: 20px;
  color: #333;
}

/* Payment Methods */
.payment-methods {
  margin-top: 20px;
}

.payment-option {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding: 12px;
  border-radius: 6px;
  cursor: pointer;
  border: 1px solid #ddd;
  transition: background-color 0.2s ease;
}

.payment-option.selected {
  background-color: #e8f4e3;
  border: 1px solid #a8d5a5;
}

.payment-option input {
  margin-right: 10px;
  display: none;
}

.payment-option svg {
  margin-right: 12px;
}

/* Button Styles */
button, .pay-btn, .mpesa-btn {
  width: 100%;
  padding: 15px;
  font-size: 1.2rem;
  color: black; /* Ensure black text */
  background-color: #3cb371;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold;
}

button:hover, .pay-btn:hover, .mpesa-btn:hover {
  background-color: #2e8b57;
}

button:active, .pay-btn:active, .mpesa-btn:active {
  background-color: #267347;
}

/* Specific Styles for Lipa na M-Pesa Button */
.mpesa-btn {
  background-color: #25a143; /* M-Pesa green */
  margin-top: 10px;
}

.mpesa-btn:hover {
  background-color: #1e8239;
}

/* Paypal Button Container */
.paypal-button-container {
  margin-top: 20px;
  text-align: center;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .payment-container {
    padding: 15px;
  }

  h2, h3 {
    font-size: 1.5rem;
  }

  .pay-btn, .mpesa-btn {
    font-size: 1rem;
  }
}
