/* Orders container */
.orders-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    font-family: 'Arial', sans-serif;
  }
  
  .orders-container h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2rem;
    color: #333;
    
  }
  
  /* Order information section */
  .order-info {
    margin-bottom: 20px;
    padding: 15px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .order-info h3 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .order-info p {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
  }
  
  .order-info p strong {
    color: #000;
  }
  
  /* Cart items section */
  .cart-items {
    margin-bottom: 20px;
    padding: 15px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .cart-items h3 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 15px;
  }
  
  .order-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
  }
  
  .order-item img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    margin-right: 20px;
    border-radius: 8px;
  }
  
  .order-item h4 {
    font-size: 1.2rem;
    margin-bottom: 5px;
    color: #333;
  }
  
  .order-item p {
    font-size: 1rem;
    color: #777;
    margin: 0;
  }
  
  /* Payment information section */
  .payment-info {
    padding: 15px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .payment-info h3 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .payment-info p {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
  }
  
  .payment-info p strong {
    color: #000;
  }
  
  /* Styling for M-Pesa payment details */
  .payment-info .mpesa-info {
    margin-top: 10px;
    font-size: 1rem;
    color: #555;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .orders-container {
      padding: 15px;
    }
  
    .order-item {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .order-item img {
      margin-bottom: 10px;
    }
  
    .orders-container h2 {
      font-size: 1.5rem;
    }
  
    .order-info, .cart-items, .payment-info {
      padding: 10px;
    }
  
    .order-info p, .payment-info p {
      font-size: 0.9rem;
    }
  
    .order-item h4, .cart-items h3, .payment-info h3 {
      font-size: 1.2rem;
    }
  }
  