/* Import the 'Gowun Batang' font */
@import url('https://fonts.googleapis.com/css2?family=Gowun+Batang:wght@400;700&display=swap');

/* Checkout Container Styling */
.checkout-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-family: 'Gowun Batang', serif; /* Same font as the footer */
}

h2 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
}

.cart-items {
  margin-top: 20px;
}

.cart-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 15px;
  margin-bottom: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.cart-image {
  width: 100px;
  height: auto;
  border-radius: 5px;
  object-fit: cover;
}

.cart-details {
  flex: 1;
  margin-left: 20px;
}

.cart-details h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.cart-details p {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 15px;
}

.quantity-control {
  display: flex;
  align-items: center;
}

.qty-btn {
  background-color: #e1e1e1;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 1.2rem;
  border-radius: 4px;
  margin: 0 10px;
}

.qty-btn:hover {
  background-color: #ccc;
}

.remove-btn {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 4px;
}

.remove-btn:hover {
  background-color: #ff3333;
}

.total-price {
  text-align: right;
  margin-top: 20px;
  font-size: 1.5rem;
  color: #333;
}

.proceed-btn {
  width: 100%;
  background-color: #3cb371;
  color: white;
  border: none;
  padding: 15px;
  font-size: 1.2rem;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 30px;
  transition: background-color 0.3s ease;
}

.proceed-btn:hover {
  background-color: #2d8f59;
}

/* Responsive Design */
@media (max-width: 768px) {
  .cart-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .cart-image {
    width: 100%;
    margin-bottom: 15px;
  }

  .cart-details {
    margin-left: 0;
  }

  .qty-btn {
    margin: 0 5px;
  }
}

@media (max-width: 480px) {
  h2 {
    font-size: 1.75rem;
  }

  .cart-details h3 {
    font-size: 1.3rem;
  }

  .cart-details p {
    font-size: 1rem;
  }

  .total-price {
    font-size: 1.2rem;
  }

  .proceed-btn {
    font-size: 1rem;
  }
}
/* Clear Cart and Go Back Home Buttons Styling */
.clear-btn,
.go-home-btn {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 15px;
  font-size: 1.2rem;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
  width: 100%;
  transition: background-color 0.3s ease;
}

.clear-btn:hover,
.go-home-btn:hover {
  background-color: #ff3333;
}

.empty-cart {
  text-align: center;
  margin-top: 50px;
}

@media (max-width: 768px) {
  .clear-btn,
  .go-home-btn {
    font-size: 1rem;
  }
}
