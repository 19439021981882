/* Intro Section */
.intro-section {
  position: relative;
  height: 100vh; /* Takes full viewport height for intro section */
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  color: white;
  text-align: center;
  overflow: hidden; /* Ensure the image doesn't overflow */
}

/* Background Image Styling */
.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the entire section */
  z-index: 1; /* Place the image below the text */
}

/* Content Styling */
.intro-section .content {
  position: relative;
  z-index: 2; /* Ensure the content (text) is above the image */
  padding: 2rem;
}

/* Headings */
.headings h1 {
  font-size: 10vw; /* Larger font size for small and large screens */
  opacity: 1; /* Start fully visible */
  transform: translateY(0); /* No translation on load */
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
  font-family: 'DM Serif Display', serif;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Text shadow */
  white-space: nowrap; /* Ensure text stays on one line */
}

.headings h4.section-footer {
  position: absolute; /* Make it stay within the section */
  bottom: 5%; /* Move it further down */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Adjust for perfect centering */
  font-size: 2.5vw; /* Responsive font size */
  opacity: 1;
  z-index: 3; /* Ensure it's above other elements */
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  font-family: 'DM Serif Display', serif;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
  white-space: nowrap; /* Ensure h4 stays on one line */
}

/* Scroll Out Animation */
.intro-section.scroll-out .linkosi-clothing-title {
  opacity: 0;
  transform: translateY(-50px); /* Move upwards and fade out */
  transition: opacity 0.5s ease-in, transform 0.5s ease-in;
}

/* Responsive Design */
@media (max-width: 768px) {
  .headings h1 {
    font-size: 8.5vw; /* Smaller font size for small devices */
    white-space: nowrap; /* Ensure text stays on one line */
  }

  .headings h4.section-footer {
    font-size: 5vw; /* Slightly larger font size for small screens */
    bottom: -200%; /* Position it a bit higher on small screens */
    white-space: nowrap; /* Ensure h4 stays on one line */
  }
}

@media (min-width: 769px) {
  .headings h1 {
    font-size: 11vw; /* Large font for bigger devices */
  }

  .headings h4.section-footer {
    font-size: 2.5vw; /* Keep it responsive */
    bottom: -20%; /* Ensure it's close to the bottom of the section */
  }
}

@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap');

/* DM Serif Display font styling */
.dm-serif-display-regular {
  font-family: "DM Serif Display", serif;
  font-weight: 400;
  font-style: normal;
}

.dm-serif-display-regular-italic {
  font-family: "DM Serif Display", serif;
  font-weight: 400;
  font-style: italic;
}
