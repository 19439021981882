@import url('https://fonts.googleapis.com/css2?family=Protest+Guerrilla&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gowun+Batang:wght@400;700&display=swap');

.footer {
  background-color: #000;
  color: #fff;
  padding: 40px 20px;
  font-family: 'Gowun Batang', serif;
  position: relative;
  overflow: hidden;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  animation: fadeInUp 1s ease-out;
}

.footer-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 40px;
}

.footer-column {
  margin: 20px;
}

.footer-column h3 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 15px;
}

.footer-column ul {
  list-style-type: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 10px;
  font-size: 14px;
  cursor: pointer;
}

.footer-column ul li a {
  text-decoration: none; /* Remove underline */
  color: #fff; /* Set default color */
  font-family: 'Gowun Batang', serif;
  transition: color 0.3s ease-in-out;
}

.footer-column ul li a:hover {
  color: #e7c035; /* Change color on hover */
  text-decoration: none; /* Ensure no underline on hover */
}

.footer-social {
  display: flex;
  gap: 15px;
  font-size: 20px;
}

.footer-social a {
  color: #fff;
  transition: color 0.3s ease-in-out;
}

.footer-social a:hover {
  color: #e7c035; /* Add hover effect */
}

.logo-text {
  font-family: 'Protest Guerrilla', sans-serif;
  font-size: 60px;
  text-align: center;
  animation: slideIn 1s ease-out;
}

.logo-text span {
  color: #e7c035;
}

.footer-bottom {
  text-align: center;
  margin-top: 20px;
  font-size: 12px;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

/* Responsive Styling */
@media (max-width: 768px) {
  .footer-links {
    flex-direction: column;
    align-items: center;
  }

  .footer-column {
    margin-bottom: 30px;
  }

  .logo-text {
    font-size: 40px;
  }

  .footer-social {
    justify-content: center;
  }
}
